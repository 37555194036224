import { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login'
import { Action } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import { Duck } from './Duck'
import { ApplicationState, Thunk } from './store'

export interface AuthenticationState {
  headers: { [name: string]: string }
  expiry: number
  authenticated: boolean
}
type AuthenticationAction = LoginAction | RefreshTokenAction | LogoutAction

interface LoginAction extends Action<string> {
  payload: GoogleLoginResponse['tokenObj']
}

type ThenArg<T> = T extends PromiseLike<infer U> ? U : T

interface RefreshTokenAction extends Action<string> {}

interface LogoutAction extends Action<string> {}

interface RefreshTokenFulfilledAction extends Action<string> {
  payload: ThenArg<ReturnType<GoogleLoginResponse['reloadAuthResponse']>>
}

export class AuthenticationDuck extends Duck<AuthenticationState, AuthenticationAction> {
  public readonly LOGIN = this.type('LOGIN')
  public readonly LOGOUT = this.type('LOGOUT')
  public readonly REFRESH_TOKEN = this.type('REFRESH_TOKEN')
  public readonly REFRESH_TOKEN_FULFILLED = this.type('REFRESH_TOKEN_FULFILLED')
  public readonly REFRESH_TOKEN_REJECTED = this.type('REFRESH_TOKEN_REJECTED')

  private waitAndRefreshToken(
    dispatch: ThunkDispatch<ApplicationState, undefined, RefreshTokenAction>,
    response: GoogleLoginResponse
  ) {
    let refreshTiming = (response.tokenObj.expires_in || 3600 - 5 * 60) * 1000
    // let refreshTiming = 5000
    setTimeout(() => {
      dispatch(this.refreshToken(response))
    }, refreshTiming)
  }

  public refreshToken(response: GoogleLoginResponse): Thunk<RefreshTokenAction> {
    return (dispatch, _getState) => {
      dispatch({ type: this.REFRESH_TOKEN, payload: response.reloadAuthResponse() })
      this.waitAndRefreshToken(dispatch, response)
    }
  }

  public login(response: GoogleLoginResponse | GoogleLoginResponseOffline): Thunk<LoginAction> {
    return (dispatch, _getState) => {
      const res = response as GoogleLoginResponse
      dispatch({ type: this.LOGIN, payload: res.tokenObj })
      this.waitAndRefreshToken(dispatch, res)
    }
  }

  public reducer(state: AuthenticationState = this.initialState(), action: AuthenticationAction) {
    switch (action.type) {
      case this.LOGIN:
      case this.REFRESH_TOKEN_FULFILLED:
        const refreshTokenFulfilledAction = action as RefreshTokenFulfilledAction
        return {
          headers: {
            authorization: `Bearer ${refreshTokenFulfilledAction.payload.id_token}`
          },
          authenticated: true,
          expiry: refreshTokenFulfilledAction.payload.expires_at
        }
      case this.REFRESH_TOKEN_REJECTED:
      case this.LOGOUT:
        return this.initialState()
      default:
        return state
    }
  }

  protected initialState() {
    return { headers: {}, authenticated: false, expiry: new Date().getTime() }
  }

  protected namespace() {
    return 'radar'
  }

  protected store(): string {
    return 'authentication'
  }
}

export const authenticationDuck = new AuthenticationDuck()
