import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import { Box } from '@material-ui/core'
import React, { useEffect } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { useDispatch, useSelector } from 'react-redux'
import Split from 'react-split'

import { layoutDuck } from '../layoutDuck'
import { QueryPanel } from '../QueryPanel'
import { ApplicationState } from '../store'
import { SnippetCard } from './SnippetCard'
import { snippetDuck } from './snippetDuck'

const ResponsiveGridLayout = WidthProvider(Responsive)

export function SnippetDashboard() {
  const dispatch = useDispatch()

  const query = useSelector((state: ApplicationState) => state.query)
  const { panelSizes } = useSelector((state: ApplicationState) => state.layout)
  const { snippets, layouts } = useSelector((state: ApplicationState) => state.snippet)
  const activeCards = useSelector(snippetDuck.activeCards)

  useEffect(() => {
    dispatch(snippetDuck.loadSnippets())
  }, [dispatch])

  return (
    <Box height="100%">
      <Box height="calc(100% - 120px)" className="split-container">
        <Split
          direction="vertical"
          sizes={panelSizes}
          onDragEnd={(sizes: number[]) => dispatch(layoutDuck.updatePanelSizes(sizes))}
        >
          <Box overflow="auto">
            <ResponsiveGridLayout
              draggableHandle=".draggable-handle"
              className="layout"
              layouts={layouts}
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
              cols={{ lg: 24, md: 20, sm: 12, xs: 8, xxs: 4 }}
              measureBeforeMount={true}
              useCSSTransforms={false}
              rowHeight={10}
              onLayoutChange={(_, allLayouts) => {
                dispatch(snippetDuck.updateLayouts(allLayouts))
              }}
            >
              {activeCards.map(card => (
                <div key={card.id}>
                  <SnippetCard
                    snippet={snippets[card.id]}
                    input={card.input}
                    onChangeInput={(id, field, value) =>
                      dispatch(snippetDuck.updateCardInput(id, field, value))
                    }
                  />
                </div>
              ))}
            </ResponsiveGridLayout>
          </Box>
          <div style={{ overflow: 'auto' }}>
            <QueryPanel {...query} />
          </div>
        </Split>
      </Box>
    </Box>
  )
}
