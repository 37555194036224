import { useCallback, useEffect, useLayoutEffect, useRef } from 'react'

type Handler = (event: KeyboardEvent) => void

export function useKeyBinding(handler: Handler, node: Document | HTMLElement | undefined) {
  const handlerRef = useRef(handler)
  useLayoutEffect(() => {
    handlerRef.current = handler
  })

  const handleKeyDown = useCallback((event: Event) => {
    handlerRef.current(event as KeyboardEvent)
  }, [])

  useEffect(() => {
    if (node) {
      node.addEventListener('keydown', handleKeyDown)
      return () => node.removeEventListener('keydown', handleKeyDown)
    }
  }, [node, handleKeyDown])
}
