import { QueryResponse } from '@grana/api/radar-admin'
import { AdminClient } from '@grana/api/radar-admin.client'
import { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport'
import { Action } from 'redux'

import { Duck } from '../Duck'
import { Thunk } from '../store'

enum Status {
  NotLoaded,
  Loading,
  Loaded
}

export type User = {
  id: string
  name: string
  email: string
}

export type UsersState = {
  listStatus: Status
  list: User[]
  user?: User
}

interface SearchAction extends Action<string> {
  payload: Promise<QueryResponse>
}

interface SearchFulfilledAction extends Action<string> {
  payload: QueryResponse
}

interface SelectAction extends Action<string> {
  payload: User
}

type UserSelectorAction = SearchAction | SearchFulfilledAction | SelectAction

export class UserSelectorDuck extends Duck<UsersState, UserSelectorAction> {
  public readonly SEARCH = this.type('SEARCH')
  public readonly SEARCH_PENDING = this.type('SEARCH_PENDING')
  public readonly SEARCH_FULFILLED = this.type('SEARCH_FULFILLED')
  public readonly SELECT = this.type('SELECT')

  reducer(state: UsersState = this.initialState(), action: UserSelectorAction) {
    switch (action.type) {
      case this.SELECT:
        return { ...state, user: (action as SelectAction).payload }
      case this.SEARCH_PENDING:
        return { ...state, listStatus: Status.Loading }
      case this.SEARCH_FULFILLED:
        const fulfilledPayload = (action as SearchFulfilledAction).payload
        if (fulfilledPayload.content.oneofKind !== 'table') {
          throw new Error('Search result must be a table!')
        }
        return {
          ...state,
          listStatus: Status.Loaded,
          list: fulfilledPayload.content.table.data.map((entry) => {
            const [id, name, email] = entry.cells.map((cell) => cell.content.oneofKind === 'text' && cell.content.text)
            return { id, name, email } as User
          })
        }
      default:
        return state
    }
  }

  public select(user: User): SelectAction {
    return {
      type: this.SELECT,
      payload: user
    }
  }

  public search(query: string): Thunk<SearchAction> {
    return (dispatch, getState) => {
      const state = getState()
      dispatch({
        type: this.SEARCH,
        payload: (async () => {
          const admin = new AdminClient(
            new GrpcWebFetchTransport({
              baseUrl: process.env['REACT_APP_GRPC_URL'] || 'http://localhost:8080',
              format: 'binary',
              meta: state.authentication.headers
            })
          )
          const { response } = await admin.query({
            query: `
              search_query = "${query.replace('"', '\\"')}"

              if search_query.match?(/^[a-f0-9]{24}$/)
                begin
                  search_result = [User.find(search_query)]
                rescue
                  search_result = []
                end
              else
                search_result = User.limit(10).any_of(
                  { name: /#{Regexp.escape(search_query)}/i },
                  { email: /#{Regexp.escape(search_query)}/i }
                )
              end
              
              table(
                  %w{ ID Nome E-mail },
                  search_result.map { |u| [u.id, u.name, u.email] }
              )
            `,
            userId: ''
          })
          return response
        })()
      })
    }
  }
  protected initialState(): UsersState {
    return {
      listStatus: Status.NotLoaded,
      list: []
    }
  }
  protected namespace(): string {
    return 'radar-admin'
  }
  protected store(): string {
    return 'users'
  }
}

export const userSelectorDuck = new UserSelectorDuck()
