import { Layouts } from 'react-grid-layout'
import { Action, AnyAction } from 'redux'

import { emptyInput, InjectedInput, Snippet } from '.'
import { UpdateLayoutsAction } from '../dashboardDuck'
import { Duck, matchAction } from '../Duck'
import { ApplicationState } from '../store'
import { snippets } from './generated'

interface LoadAction extends Action<string> {
  payload: Snippet[]
}

interface ToggleFavoriteAction extends Action<string> {
  payload: { id: string }
}

interface UpdateCardInputAction extends Action<string> {
  payload: { id: string; field: string; value: string }
}

interface CardState {
  input: InjectedInput
}

export type SnippetState = {
  snippets: { [id: string]: Snippet }
  cards: { [id: string]: CardState | undefined }
  layouts: Layouts
}

const defaultLayouts: Layouts = {
  lg: [],
  md: [],
  sm: [],
  xs: [],
  xxs: []
}

export class SnippetDuck extends Duck<SnippetState, AnyAction> {
  public readonly LOAD = this.type('LOAD')
  public readonly TOGGLE_FAVORITE = this.type('TOGGLE_FAVORITE')
  public readonly UPDATE_CARD_INPUT = this.type('UPDATE_CARD_INPUT')
  public readonly UPDATE_LAYOUTS = this.type('UPDATE_LAYOUTS')

  public reducer(state: SnippetState = this.initialState(), action: AnyAction): SnippetState {
    if (matchAction<LoadAction>(action, this.LOAD)) {
      const snippets = state.snippets ?? {}
      return {
        ...state,
        snippets: Object.fromEntries(
          action.payload.map(snippet => [
            snippet.id,
            { ...snippet, favorite: snippets[snippet.id]?.favorite }
          ])
        )
      }
    }

    if (matchAction<ToggleFavoriteAction>(action, this.TOGGLE_FAVORITE)) {
      const { id } = action.payload
      const snippet = state.snippets[id]
      const favorite = !snippet.favorite
      const layouts = state.layouts ?? defaultLayouts
      return {
        ...state,
        snippets: {
          ...state.snippets,
          [id]: { ...snippet, favorite }
        },
        cards: {
          ...state.cards,
          [id]: favorite ? { input: emptyInput(snippet) } : undefined
        },
        layouts: {
          lg: [...layouts.lg, { i: id, x: 0, y: 0, w: 6, h: 9 }],
          md: [...layouts.md, { i: id, x: 0, y: 0, w: 6, h: 9 }],
          sm: [...layouts.sm, { i: id, x: 0, y: 0, w: 6, h: 9 }],
          xs: [...layouts.xs, { i: id, x: 0, y: 0, w: 6, h: 9 }],
          xxs: [...layouts.xxs, { i: id, x: 0, y: 0, w: 6, h: 9 }]
        }
      }
    }

    if (matchAction<UpdateCardInputAction>(action, this.UPDATE_CARD_INPUT)) {
      const { id, field, value } = action.payload
      const card = state.cards[id]
      const input = card?.input ?? {}
      return {
        ...state,
        cards: {
          ...state.cards,
          [id]: { ...card, input: { ...input, [field]: value } }
        }
      }
    }

    if (matchAction<UpdateLayoutsAction>(action, this.UPDATE_LAYOUTS)) {
      return { ...state, layouts: action.payload }
    }

    return state
  }

  public loadSnippets(): LoadAction {
    return {
      type: this.LOAD,
      payload: snippets
    }
  }

  public toggleFavorite(id: string): ToggleFavoriteAction {
    return {
      type: this.TOGGLE_FAVORITE,
      payload: { id }
    }
  }

  public updateCardInput(id: string, field: string, value: string): UpdateCardInputAction {
    return {
      type: this.UPDATE_CARD_INPUT,
      payload: { id, field, value }
    }
  }

  public updateLayouts(layouts: Layouts): UpdateLayoutsAction {
    return {
      type: this.UPDATE_LAYOUTS,
      payload: layouts
    }
  }

  public activeCards(state: ApplicationState): Array<CardState & { id: string }> {
    const { cards } = state.snippet
    function isCardActive(
      state: [string | undefined, CardState | undefined]
    ): state is [string, CardState] {
      return state[1] !== undefined
    }
    return Object.entries(cards ?? {})
      .filter(isCardActive)
      .map(([id, state]) => ({ id, ...state }))
  }

  public list(state: ApplicationState): Snippet[] {
    return Object.values(state.snippet.snippets ?? {})
  }

  protected initialState() {
    return {
      snippets: {},
      cards: {},
      layouts: defaultLayouts
    }
  }
  protected namespace(): string {
    return 'radar-admin'
  }
  protected store(): string {
    return 'snippet'
  }
}

export const snippetDuck = new SnippetDuck()
