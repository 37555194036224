import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { dashboardDuck, Theme } from './dashboardDuck'
import { ApplicationState } from './store'
import { ThemeButton } from './ThemeButton'

export const ThemeButtonContainer = () => {
  const dispatch = useDispatch()
  const theme = useSelector((state: ApplicationState) => state.dashboard.theme)
  function handleClick() {
    if (theme === Theme.Dark) {
      dispatch(dashboardDuck.changeTheme(Theme.Light))
      return
    }
    dispatch(dashboardDuck.changeTheme(Theme.Dark))
  }

  return <ThemeButton onClick={handleClick} />
}
