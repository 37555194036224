import { Box, IconButton, SvgIcon, Tooltip } from '@material-ui/core'
import { AddBox, PlayArrowRounded } from '@material-ui/icons'
import React, { MutableRefObject, useState } from 'react'

import { isInputMissing, Snippet, SnippetAction, SnippetEffect } from '.'
import { useKeyBinding } from '../useKeyBinding'
import { RiskySnippetDialog } from './RiskySnippetDialog'
import { useSnippetActions } from './snippetHooks'
import { SnippetInputDialog } from './SnippetInputDialog'

interface Props {
  snippet: Snippet
  requirementMissing?: boolean
  size?: 'small'
  iconStyles?: { copy?: React.CSSProperties }
  style?: React.CSSProperties
  parentRef?: MutableRefObject<HTMLElement | undefined>
  onAction?: (snippet: Snippet) => void
}

export function SnippetToolbar({ snippet, ...props }: Props) {
  const [riskyDialogOpen, setRiskyDialogOpen] = useState(false)
  const [inputDialogOpen, setInputDialogOpen] = useState(false)

  const tryActionIfNotRisky = (action: SnippetAction, snippet: Snippet) => {
    if (snippet.risky) {
      setPostInputAction(() => action)
      setRiskyDialogOpen(true)
    } else {
      runActionIfCorrectInput(action, snippet)
    }
  }

  const runActionIfCorrectInput = (action: SnippetAction, snippet: Snippet) => {
    if (isInputMissing(snippet)) {
      setPostInputAction(() => action)
      setInputDialogOpen(true)
    } else {
      action(snippet)
    }
  }

  const { run, add, copy } = useSnippetActions(snippet => props.onAction && props.onAction(snippet))
  const [runPostInputAction, setPostInputAction] = useState<SnippetAction>(() => run)

  useKeyBinding(event => {
    if (event.ctrlKey && event.key === 'Enter') {
      tryActionIfNotRisky(run, snippet)
    }
  }, props.parentRef?.current)

  return (
    <Box display="flex" flex={1} style={props.style}>
      <RunButton snippet={snippet} {...props} onClick={() => tryActionIfNotRisky(run, snippet)} />
      <AddButton snippet={snippet} {...props} onClick={() => tryActionIfNotRisky(add, snippet)} />
      <CopyButton snippet={snippet} {...props} onClick={() => copy(snippet)} />
      <RiskySnippetDialog
        open={riskyDialogOpen}
        snippet={snippet}
        onCancel={() => setRiskyDialogOpen(false)}
        onConfirm={() => {
          setRiskyDialogOpen(false)
          runActionIfCorrectInput(runPostInputAction, snippet)
        }}
      />
      <SnippetInputDialog
        open={inputDialogOpen}
        snippet={snippet}
        onCancel={() => setInputDialogOpen(false)}
        onConfirm={snippet => {
          setInputDialogOpen(false)
          runPostInputAction(snippet)
        }}
      />
    </Box>
  )
}

type ButtonProps = Pick<Props, 'snippet' | 'requirementMissing' | 'size' | 'iconStyles'> & {
  onClick: () => void
}

function RunButton(props: ButtonProps) {
  return (
    <Tooltip title="Executar">
      <span>
        <IconButton
          disabled={props.requirementMissing ?? false}
          size={props.size}
          onClick={props.onClick}
        >
          <PlayArrowRounded />
        </IconButton>
      </span>
    </Tooltip>
  )
}

function AddButton(props: ButtonProps) {
  return (
    <Tooltip title="Adicionar ao dashboard">
      <span>
        <IconButton
          disabled={
            props.snippet.effect === SnippetEffect.SideEffect || (props.requirementMissing ?? false)
          }
          size={props.size}
          onClick={props.onClick}
        >
          <AddBox />
        </IconButton>
      </span>
    </Tooltip>
  )
}

function CopyButton(props: ButtonProps) {
  return (
    <Tooltip title="Copiar">
      <IconButton size={props.size} style={props.iconStyles?.copy} onClick={props.onClick}>
        <CopyContentIcon />
      </IconButton>
    </Tooltip>
  )
}

function CopyContentIcon() {
  return (
    <SvgIcon>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
    </SvgIcon>
  )
}
