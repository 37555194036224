import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import React, { Component } from 'react'
import { Layouts, Responsive, WidthProvider } from 'react-grid-layout'

import { DashboardState } from './dashboardDuck'
import { QueryPanel } from './QueryPanel'

const ResponsiveGridLayout = WidthProvider(Responsive)

interface Props extends DashboardState {
  onChange?: (layouts: Layouts) => void
  onDeleteClick: (queryId: string) => void
  onAutoRefreshToggle: (queryId: string) => void
  onRefreshClick: (queryId: string) => void
  onChangeInput: (queryId: string, field: string, value: string | number) => void
}
export class Dashboard extends Component<Props> {
  render() {
    return (
      <ResponsiveGridLayout
        draggableHandle=".draggable-handle"
        className="layout"
        layouts={this.props.layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        measureBeforeMount={true}
        useCSSTransforms={false}
        onLayoutChange={(currentLayout, allLayouts) => {
          this.props.onChange && this.props.onChange(allLayouts)
        }}
      >
        {Object.entries(this.props.queries).map(([id, query]) => {
          return (
            <div key={id}>
              <QueryPanel
                draggable
                {...query}
                onDeleteClick={() => {
                  this.props.onDeleteClick(id)
                }}
                onAutoRefreshToggle={() => {
                  this.props.onAutoRefreshToggle(id)
                }}
                onRefreshClick={() => this.props.onRefreshClick(id)}
                onChangeInput={(field, value) => this.props.onChangeInput(id, field, value)}
              />
            </div>
          )
        })}
      </ResponsiveGridLayout>
    )
  }
}
