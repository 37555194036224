import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Dashboard } from './Dashboard'
import { dashboardDuck } from './dashboardDuck'
import { ApplicationState } from './store'

function DashboardContainer() {
  const dashboard = useSelector((state: ApplicationState) => state.dashboard)
  const dispatch = useDispatch()

  return (
    <Dashboard
      queries={dashboard.queries}
      layouts={dashboard.layouts}
      theme={dashboard.theme}
      onDeleteClick={queryId => {
        dispatch(dashboardDuck.delete(queryId))
      }}
      onChange={layouts => {
        dispatch(dashboardDuck.updateLayouts(layouts))
      }}
      onAutoRefreshToggle={queryId => {
        dispatch(dashboardDuck.toggleAutoRefresh(queryId))
      }}
      onRefreshClick={queryId => {
        dispatch(dashboardDuck.load(queryId))
      }}
      onChangeInput={(queryId, field, value) => {
        dispatch(dashboardDuck.updateQueryInput(queryId, field, value))
      }}
    />
  )
}

export default DashboardContainer
