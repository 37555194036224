import { Box, IconButton, Paper, Toolbar, Typography } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import React, { Component } from 'react'

import { PanelToolbar } from './PanelToolbar'
import { QueryState, Status } from './queryDuck'
import { Spinner } from './Spinner'
import { Table } from './Table'

interface Props extends QueryState {
  onDeleteClick?: () => void
  onAutoRefreshToggle?: () => void
  onRefreshClick?: () => void
  onChangeInput?: (field: string, value: string | number) => void
  draggable?: boolean
}

export class QueryPanel extends Component<Props> {
  render() {
    const { card, error, title } = this.props
    switch (this.props.status) {
      // @ts-ignore
      case Status.NotLoaded:
        if (this.props.onRefreshClick) {
          return (
            <Paper elevation={4} style={{ height: '100%' }}>
              <Toolbox {...this.props} />
              <Box height="60%" width="100%" className="center-container">
                <Box className="center-item">
                  <IconButton
                    onClick={() => {
                      this.props.onRefreshClick!()
                    }}
                  >
                    <RefreshIcon fontSize="large" />
                  </IconButton>
                </Box>
              </Box>
            </Paper>
          )
        }
      // eslint-disable-next-line
      case Status.Loading:
        return (
          <Paper elevation={4} style={{ height: '100%' }}>
            <Toolbox
              {...this.props}
              title={`Carregando ${title || card?.options?.title || ''}...`}
            />
            <Spinner />
          </Paper>
        )
      case Status.TextLoaded:
        return (
          <Paper
            elevation={4}
            style={{
              whiteSpace: 'pre-wrap',
              fontFamily: 'monospace'
            }}
          >
            <Toolbox {...this.props} />
            <Box m={2}>{this.props.text}</Box>
          </Paper>
        )
      case Status.TableLoaded:
        return (
          <Table
            {...this.props}
            {...this.props.table!}
            draggable={this.props.draggable}
            onAutoRefreshToggle={this.props.onAutoRefreshToggle}
            autoRefresh={this.props.autoRefresh}
            onRefreshClick={this.props.onRefreshClick}
            onDeleteClick={
              this.props.onDeleteClick
                ? () => this.props.onDeleteClick && this.props.onDeleteClick()
                : undefined
            }
            onChangeInput={this.props.onChangeInput}
          />
        )
      case Status.CardLoaded:
        return (
          <Paper
            elevation={4}
            style={{
              whiteSpace: 'pre-wrap',
              fontFamily: 'monospace'
            }}
          >
            <Toolbox {...this.props} title={`${card?.options?.title || title || ''}`} />
            <Box m={2}>{card?.text || ''}</Box>
          </Paper>
        )

      case Status.Failed:
        return (
          <Paper
            elevation={4}
            style={{
              whiteSpace: 'pre-wrap',
              fontFamily: 'monospace'
            }}
          >
            <Toolbox
              {...this.props}
              title={`Snippet com Erro ${title || card?.options?.title || ''}`}
            />
            <Box m={2}>{error}</Box>
          </Paper>
        )
      default:
        throw new Error(`Status ${this.props.status} is invalid!`)
    }
  }
}

const Toolbox = (props: Props) => {
  return (
    <Toolbar>
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        alignContent="center"
        alignItems="center"
      >
        <Typography variant="h6" style={{ flex: '1 1 auto' }}>
          {props.title}
        </Typography>
        <Box textAlign="right">
          <PanelToolbar
            onDeleteClick={props.onDeleteClick}
            onRefreshClick={props.onRefreshClick}
            onAutoRefreshToggle={props.onAutoRefreshToggle}
            autoRefreshEnabled={props.autoRefresh}
            draggable={props.draggable}
          />
        </Box>
      </Box>
    </Toolbar>
  )
}
