import { Action, AnyAction } from 'redux'

import { Duck, matchAction } from './Duck'

interface UpdatePanelSizesAction extends Action<string> {
  payload: number[]
}

export type LayoutState = {
  panelSizes: number[]
}

export class LayoutDuck extends Duck<LayoutState, AnyAction> {
  public readonly UPDATE_PANEL_SIZES = this.type('UPDATE_PANEL_SIZES')

  public reducer(state: LayoutState = this.initialState(), action: AnyAction): LayoutState {
    if (matchAction<UpdatePanelSizesAction>(action, this.UPDATE_PANEL_SIZES)) {
      return { ...state, panelSizes: action.payload }
    }

    return state
  }

  public updatePanelSizes(sizes: number[]): UpdatePanelSizesAction {
    return {
      type: this.UPDATE_PANEL_SIZES,
      payload: sizes
    }
  }

  protected initialState() {
    return {
      panelSizes: [75, 25]
    }
  }
  protected namespace(): string {
    return 'radar-admin'
  }
  protected store(): string {
    return 'layout'
  }
}

export const layoutDuck = new LayoutDuck()
