import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Snippet } from '.'
import { ApplicationState } from '../store'
import { snippetDuck } from './snippetDuck'
import { SnippetList } from './SnippetList'

interface Props {
  onAction?: (snippet: Snippet) => void
}

export function SnippetListContainer(props: Props) {
  const dispatch = useDispatch()

  const snippets = useSelector(snippetDuck.list)
  const query = useSelector((state: ApplicationState) => state.query)

  useEffect(() => {
    dispatch(snippetDuck.loadSnippets())
  }, [dispatch])

  return (
    <SnippetList
      {...query}
      snippets={snippets}
      onToggleFavorite={id => dispatch(snippetDuck.toggleFavorite(id))}
      onAction={snippet => props.onAction && props.onAction(snippet)}
    />
  )
}
