import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { dashboardDuck } from './dashboardDuck'
import { Editor } from './Editor'
import { layoutDuck } from './layoutDuck'
import { queryDuck } from './queryDuck'
import { ApplicationState } from './store'

export function EditorContainer() {
  const dispatch = useDispatch()

  const editor = useSelector((state: ApplicationState) => state.query)
  const { panelSizes } = useSelector((state: ApplicationState) => state.layout)

  return (
    <Editor
      {...editor}
      panelSizes={panelSizes}
      onRun={() => {
        dispatch(queryDuck.query())
      }}
      onSave={() => {
        dispatch(dashboardDuck.add(editor.expression))
      }}
      onExpressionChange={expression => {
        dispatch(queryDuck.updateExpression(expression))
      }}
      onChangePanelSizes={sizes => dispatch(layoutDuck.updatePanelSizes(sizes))}
    />
  )
}
