import { Box, CircularProgress, Fade } from '@material-ui/core'
import React, { Component } from 'react'

export class Spinner extends Component {
  render() {
    return (
      <Box height="80%" width="100%" className="center-container">
        <Box
          className="center-item"
          // flexGrow={1}
          // display="flex"
          // width="100%"
          // height="100%"
          // alignContent="center"
          // justifyContent="center"
        >
          <Fade
            in={true}
            style={{
              transitionDelay: '200ms'
            }}
          >
            <CircularProgress />
          </Fade>
        </Box>
      </Box>
    )
  }
}
