import MUIDataTable from 'mui-datatables'
import React, { Component } from 'react'

import { PanelToolbar } from './PanelToolbar'
import { InjectedInput, TableOptions } from './queryDuck'

type Props = {
  options?: TableOptions
  columns: string[]
  data: (string | number)[][]
  onDeleteClick?: () => void
  onRefreshClick?: () => void
  onAutoRefreshToggle?: () => void
  onChangeInput?: (field: string, value: string | number) => void
  draggable?: boolean
  autoRefresh: boolean
  input?: InjectedInput | undefined
}

export class Table extends Component<Props> {
  render() {
    const { options, data, columns, input } = this.props

    return (
      <MUIDataTable
        title={(options && options.title) || ''}
        data={data}
        columns={columns}
        options={{
          onChangePage: page => {
            this.props.onChangeInput && this.props.onChangeInput('page', page)
          },
          onSearchChange: search => {
            this.props.onChangeInput && this.props.onChangeInput('search', search)
          },
          searchText: input?.search as string | undefined,
          page: input?.page as number | undefined,
          responsive: 'stacked',
          selectableRows: 'none',
          viewColumns: false,
          pagination: (options && options.pagination) || false,
          rowsPerPage: (options && options.perPage) || 10,
          print: false,
          setTableProps: () => ({
            size: 'small'
          }),
          customToolbar: () => (
            <PanelToolbar
              onRefreshClick={this.props.onRefreshClick}
              onDeleteClick={this.props.onDeleteClick}
              onAutoRefreshToggle={this.props.onAutoRefreshToggle}
              draggable={this.props.draggable}
              autoRefreshEnabled={this.props.autoRefresh}
            />
          )
        }}
      />
    )
  }
}
