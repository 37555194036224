import { IconButton, Tooltip } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import FlashOffIcon from '@material-ui/icons/FlashOff'
import FlashOnIcon from '@material-ui/icons/FlashOn'
import OpenWithIcon from '@material-ui/icons/OpenWith'
import RefreshIcon from '@material-ui/icons/Refresh'
import React from 'react'

type Props = {
  onDeleteClick?: () => void
  onRefreshClick?: () => void
  onAutoRefreshToggle?: () => void
  draggable?: boolean
  autoRefreshEnabled: boolean
}

export function PanelToolbar(props: Props) {
  return (
    <React.Fragment>
      {props.onAutoRefreshToggle && (
        <Tooltip title="Carregar automaticamente">
          <IconButton onClick={props.onAutoRefreshToggle}>
            {props.autoRefreshEnabled ? <FlashOnIcon /> : <FlashOffIcon />}
          </IconButton>
        </Tooltip>
      )}
      {props.onRefreshClick && (
        <Tooltip title="Recarregar">
          <IconButton onClick={props.onRefreshClick}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      )}
      {props.onDeleteClick && (
        <Tooltip title="Apagar">
          <IconButton onClick={props.onDeleteClick}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
      {props.draggable && (
        <Tooltip title="Mover">
          <IconButton>
            <OpenWithIcon className="draggable-handle" />
          </IconButton>
        </Tooltip>
      )}
    </React.Fragment>
  )
}
