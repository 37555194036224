export enum SnippetCategory {
  B3i = 'B3',
  Portfolio = 'Carteira',
  Invoice = 'Cobrança',
  Reconciliation = 'Conciliação',
  IncomeTax = 'IR',
  MarketData = 'Mercado',
  Position = 'Posição',
  User = 'Usuário',
  Diagnostic = 'Diagnóstico'
}

export enum SnippetEffect {
  Data = 'Dashboard',
  SideEffect = 'Ação'
}

export interface Snippet {
  id: string
  name: string
  content: string
  version: string
  category: SnippetCategory
  effect: SnippetEffect
  favorite?: boolean
  risky?: boolean
  description?: string
  inputConfig?: SnippetNamedInputConfig
  input?: InjectedInput
}

interface InputConfig<T extends string> {
  type: T
  label?: string
  helper?: string
  required?: boolean
}

type NumberInputConfig = InputConfig<'number'>
type StringInputConfig = InputConfig<'string'>
type DateInputConfig = InputConfig<'date'>
type BooleanInputConfig = InputConfig<'boolean'>

interface EnumChoice {
  label: string
  value: string
}

interface EnumInputConfig extends InputConfig<'enum'> {
  choices: EnumChoice[]
}

export type SnippetInputConfig =
  | NumberInputConfig
  | StringInputConfig
  | DateInputConfig
  | BooleanInputConfig
  | EnumInputConfig
export type SnippetNamedInputConfig = { [name: string]: SnippetInputConfig }

export type InjectedInput = { [name: string]: string | null }

export type SnippetAction = (snippet: Snippet) => void

export function withInjectedInput(snippet: Snippet): Snippet {
  return {
    ...snippet,
    content: injectedInputContent(snippet)
  }
}

export function injectedInputContent(snippet: Snippet) {
  return `${Object.entries(snippet.input ?? emptyInput(snippet))
    .map(([k, v]) => injectInput(snippet, k, v))
    .join('\n')}\n${snippet.content}`
}

const EMPTY_INPUT = 'nil'

function injectInput({ id, inputConfig }: Snippet, field: string, value: string | null) {
  if (!inputConfig || !inputConfig[field]) {
    console.warn(`input ${field} para snippet "${id}" não encontrado`)
    return `${field} = ${EMPTY_INPUT}`
  }
  const { type } = inputConfig[field]
  return `${field} = ${inputFormatter[type](value)}`
}

const inputFormatter: { [type in SnippetInputConfig['type']]: (value: string | null) => string } = {
  string: stringInput,
  date: stringInput,
  number: defaultInput,
  boolean: defaultInput,
  enum: defaultInput
}

function stringInput(value: string | null) {
  return value === '' || value === null ? EMPTY_INPUT : `'${value}'`
}

function defaultInput(value: string | null) {
  return value === '' || value === null ? EMPTY_INPUT : value
}

export function emptyInput(snippet: Snippet): InjectedInput {
  return Object.fromEntries(Object.entries(snippet.inputConfig ?? {}).map(([name]) => [name, null]))
}

export function isInputMissing(snippet: Snippet) {
  // @todo: adicionar condição: input required não preenchido
  return snippet.inputConfig !== undefined && snippet.input === undefined
}

export function isRequirementMissing(snippet: Snippet, input: InjectedInput) {
  const inputConfig = snippet?.inputConfig ?? {}
  const requiredInputs = Object.keys(inputConfig ?? {}).filter(name => inputConfig![name].required)
  return requiredInputs.some(name => input[name] === null || input[name] === '')
}
