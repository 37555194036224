import { Action, applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { combineLoads, load, save } from 'redux-localstorage-simple'
import promise from 'redux-promise-middleware'
import thunk, { ThunkAction } from 'redux-thunk'

import { authenticationDuck, AuthenticationState } from './authenticationDuck'
import { dashboardDuck, DashboardState } from './dashboardDuck'
import { layoutDuck, LayoutState } from './layoutDuck'
import { queryDuck, QueryState } from './queryDuck'
import { snippetDuck, SnippetState } from './snippets/snippetDuck'
import { userSelectorDuck, UsersState } from './users/userSelectorDuck'

export type Thunk<A extends Action> = ThunkAction<void, ApplicationState, undefined, A>

export interface ApplicationState {
  userSelector: UsersState
  query: QueryState
  dashboard: DashboardState
  authentication: AuthenticationState
  snippet: SnippetState
  layout: LayoutState
}

export const reducers = {
  userSelector: userSelectorDuck.REDUCER,
  query: queryDuck.REDUCER,
  dashboard: dashboardDuck.REDUCER,
  authentication: authenticationDuck.REDUCER,
  snippet: snippetDuck.REDUCER,
  layout: layoutDuck.REDUCER
}

const middleware = applyMiddleware(
  thunk,
  promise,
  ...Object.keys(reducers)
    .filter(state => state !== 'authentication')
    .map(store => save({ states: [store], namespace: `redux_localstorage_${store}` }))
)

export default createStore(
  combineReducers<ApplicationState>(reducers),
  combineLoads(
    ...Object.keys(reducers)
      .filter(state => state !== 'authentication')
      .map(store => load({ states: [store], namespace: `redux_localstorage_${store}` }))
  ),
  composeWithDevTools(middleware)
)
