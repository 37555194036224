import { IconButton } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import React from 'react'

type Props = {
  onClick?: () => void
}

export function RefreshButton(props: Props) {
  return (
    <IconButton
      color="inherit"
      onClick={() => {
        props.onClick && props.onClick()
      }}
    >
      <RefreshIcon />
    </IconButton>
  )
}
