import { Box, CssBaseline, Theme, ThemeProvider } from '@material-ui/core'
import { SnackbarProvider } from 'notistack'
import React, { useEffect, useState } from 'react'
import { Provider, useSelector } from 'react-redux'
import './App.css'
import { Authentication } from './Authentication'
import { Theme as ThemeName } from './dashboardDuck'
import { Main } from './Main'
import store, { ApplicationState } from './store'
import { DarkTheme, LightTheme } from './utils/theme'

const App = () => {
  return (
    <Provider store={store}>
      <AppContent />
    </Provider>
  )
}

const AppContent = () => {
  const { theme } = useSelector((state: ApplicationState) => state.dashboard)
  const [currentTheme, setCurrentTheme] = useState<Theme | null>(null)

  useEffect(() => {
    if (theme === ThemeName.Dark) {
      setCurrentTheme(DarkTheme)
    } else {
      setCurrentTheme(LightTheme)
    }
  }, [theme])

  if (currentTheme === null) {
    return null
  }
  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline />
      <Authentication>
        <SnackbarProvider>
          <Box width="100%" height="100%">
            <Box height={60} />
            <Main />
          </Box>
        </SnackbarProvider>
      </Authentication>
    </ThemeProvider>
  )
}

export default App
