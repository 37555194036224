import { createTheme } from '@material-ui/core'
import { dark, light, TypeObject } from '@material-ui/core/styles/createPalette'

export const DarkTheme = selectTheme(dark, 'dark')
export const LightTheme = selectTheme(light, 'light')

function selectTheme(customPalette: TypeObject, id: 'dark' | 'light') {
  return createTheme({
    palette: {
      ...customPalette,
      primary: {
        main: '#303030'
      },
      secondary: {
        main: '#04773B'
      }
    },
    overrides: {
      MuiTableCell: {
        head: { borderColor: id === 'light' ? '#e0e0e0' : '#303030' },
        body: { borderColor: id === 'light' ? '#e0e0e0' : '#303030' }
      },
      MuiDialogContent: {
        dividers: {
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
        }
      }
    }
  })
}
