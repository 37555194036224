import React, { FunctionComponent } from 'react'
import { GoogleLogin } from 'react-google-login'
import { useDispatch, useSelector } from 'react-redux'
import { authenticationDuck } from './authenticationDuck'
import { ApplicationState } from './store'

const isDev = process.env.NODE_ENV === 'development'
const authenticatedSelector = (state: ApplicationState) => state.authentication.authenticated

export const Authentication: FunctionComponent = ({ children }) => {
  const storeAuthenticated = useSelector(authenticatedSelector)
  const authenticated = isDev || storeAuthenticated
  const dispatch = useDispatch()
  return (
    <React.Fragment>
      <div
        style={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          flexWrap: 'wrap',
          zoom: 1.5,
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          backgroundColor: '#aaa',
          visibility: authenticated ? 'hidden' : 'visible'
        }}
      >
        <GoogleLogin
          clientId="348334539926-vfpncpg1rr1iiq5dtp0qv1f6v6pup55f.apps.googleusercontent.com"
          buttonText="Login"
          onSuccess={r => dispatch(authenticationDuck.login(r))}
          onFailure={e => console.info('erro', e)}
          cookiePolicy="single_host_origin"
          isSignedIn={true}
          hostedDomain="grana.capital"
        />
      </div>
      {authenticated && children}
    </React.Fragment>
  )
}
