import { TextField } from '@material-ui/core'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import React, { Component } from 'react'

import { User } from './userSelectorDuck'

type Props = {
  onInputChange?: (text: string) => void
  onSelect?: (user: User) => void
  options: User[]
  selected?: User
}

export class UserSelector extends Component<Props> {
  render() {
    return (
      <Autocomplete
        value={this.props.selected}
        options={this.props.options}
        getOptionLabel={option => `${option.name} <${option.email}>`}
        filterOptions={createFilterOptions({
          stringify: option => `${option.name} ${option.email} ${option.id}`
        })}
        style={{ width: 500, backgroundColor: '#30303030', borderRadius: 20 }}
        onInputChange={(event, value) => {
          this.props.onInputChange && this.props.onInputChange(value)
        }}
        placeholder="Usuário"
        disableClearable={true}
        forcePopupIcon={false}
        className="user-selector"
        onChange={(event: any, user: User | null) => {
          this.props.onSelect && user && this.props.onSelect(user)
        }}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              ...params.InputProps,
              style: {
                color: '#fff'
              }
            }}
          />
        )}
      />
    )
  }
}
