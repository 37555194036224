import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'
import React from 'react'

import { Snippet } from '.'

interface RiskySnippetDialogProps {
  open: boolean
  snippet: Snippet
  onCancel: () => void
  onConfirm: () => void
}

export function RiskySnippetDialog(props: RiskySnippetDialogProps) {
  return (
    <Dialog open={props.open} onClose={props.onCancel}>
      <DialogTitle>Executar operação de risco?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          A operação "{props.snippet.name}" foi classificada como um risco. Executá-la pode ter
          consequências indesejadas.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel}>Cancelar</Button>
        <Button onClick={props.onConfirm} autoFocus>
          Executar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
