import { Dialog, IconButton } from '@material-ui/core'
import { Code } from '@material-ui/icons'
import React, { useState } from 'react'

import { SnippetListContainer } from './SnippetListContainer'

export const ShowSnippetListButton = () => {
  const [snippetDialogOpen, setSnippetDialogOpen] = useState(false)
  const closeSnippetDialog = () => setSnippetDialogOpen(false)

  return (
    <div>
      <Dialog
        open={snippetDialogOpen}
        fullWidth={true}
        maxWidth="md"
        scroll="body"
        onClose={closeSnippetDialog}
      >
        <SnippetListContainer onAction={closeSnippetDialog} />
      </Dialog>
      <IconButton color="inherit" onClick={() => setSnippetDialogOpen(true)}>
        <Code />
      </IconButton>
    </div>
  )
}
