import {
  Box,
  Checkbox,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import {
  FilterList as FilterListIcon,
  Star as StarIcon,
  StarBorder as StarBorderIcon,
  Warning as WarningIcon
} from '@material-ui/icons'
import React, { useEffect, useState } from 'react'

import { Snippet, SnippetCategory, SnippetEffect } from '.'
import { SnippetToolbar } from './SnippetToolbar'

interface Props {
  snippets: Snippet[]
  onAction?: (snippet: Snippet) => void
  onToggleFavorite?: (id: string) => void
}

export function SnippetList(props: Props) {
  const updateFilter = (field: keyof Snippet, value: any) => {
    setFilter(prev => ({ ...prev, [field]: value }))
  }
  const [filteredSnippets, setFilteredSnippets] = useState(props.snippets)
  const [filter, setFilter] = useState<Partial<Snippet> | null>(null)

  useEffect(() => {
    if (filter === null) {
      setFilteredSnippets(props.snippets)
      return
    }
    let filterResult = props.snippets
    Object.keys(filter).forEach(field => {
      const filterValue = filter[field as keyof Snippet]
      if (filterValue === null) return
      filterResult = filterResult.filter(snippet => snippet[field as keyof Snippet] === filterValue)
    })
    setFilteredSnippets(filterResult)
  }, [props.snippets, filter])

  return (
    <Box>
      <Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <FilterableTableHeader
                  choices={Object.values(SnippetEffect)}
                  selected={(filter && filter['effect']) ?? null}
                  onSelect={effect => updateFilter('effect', effect)}
                >
                  Tipo
                </FilterableTableHeader>
                <FilterableTableHeader
                  choices={Object.values(SnippetCategory)}
                  selected={(filter && filter['category']) ?? null}
                  onSelect={category => updateFilter('category', category)}
                >
                  Categoria
                </FilterableTableHeader>
                <TableCell align="right">Versão</TableCell>
                <TableCell align="right">Risco</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredSnippets.map(snippet => (
                <TableRow key={snippet.name}>
                  <TableCell component="th" scope="row">
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <Box>
                        <Typography>{snippet.name}</Typography>
                      </Box>
                      <Checkbox
                        icon={<StarBorderIcon />}
                        checkedIcon={<StarIcon />}
                        checked={!!snippet.favorite}
                        onChange={() =>
                          props.onToggleFavorite && props.onToggleFavorite(snippet.id)
                        }
                      />
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Chip
                      size="small"
                      key={snippet.effect}
                      label={snippet.effect}
                      variant="outlined"
                      style={{ color: '#969696', marginLeft: 5, marginBottom: 5 }}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Chip
                      label={snippet.category}
                      color="secondary"
                      style={{ marginLeft: 5, marginBottom: 5 }}
                    />
                  </TableCell>
                  <TableCell align="right">{snippet.version}</TableCell>
                  <TableCell align="right">{snippet.risky ? <WarningIcon /> : null}</TableCell>
                  <TableCell align="right">
                    <SnippetToolbar
                      snippet={snippet}
                      size="small"
                      onAction={snippet => props.onAction && props.onAction(snippet)}
                      style={{ justifyContent: 'space-between' }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}

interface FilterableTableHeaderProps<T extends string> {
  choices: T[]
  selected: T | null
  children: string
  onSelect: (value: T | null) => void
}

function FilterableTableHeader<T extends string>(props: FilterableTableHeaderProps<T>) {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const popoverOpen = Boolean(anchorElement)
  return (
    <TableCell align="right">
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end'
        }}
      >
        <Box>{props.children}</Box>
        <IconButton size="small" onClick={event => setAnchorElement(event.currentTarget)}>
          <FilterListIcon />
        </IconButton>
        <Popover
          open={popoverOpen}
          anchorEl={anchorElement}
          onClose={() => setAnchorElement(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <FilterList
            choices={props.choices}
            selected={props.selected}
            onSelect={value => {
              setAnchorElement(null)
              props.onSelect(value)
            }}
          />
        </Popover>
      </Box>
    </TableCell>
  )
}

interface FilterListProps<T extends string> {
  choices: T[]
  selected: T | null
  onSelect: (value: T | null) => void
}

function FilterList<T extends string>(props: FilterListProps<T>) {
  return (
    <List>
      <ListItem selected={props.selected === null} button onClick={() => props.onSelect(null)}>
        <ListItemText>Todos</ListItemText>
      </ListItem>
      {props.choices.map(value => (
        <ListItem
          key={value}
          selected={props.selected === value}
          onClick={() => props.onSelect(value)}
          button
        >
          <ListItemText>{value}</ListItemText>
        </ListItem>
      ))}
    </List>
  )
}
