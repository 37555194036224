import { IconButton } from '@material-ui/core'
import Brightness4Icon from '@material-ui/icons/Brightness4'
import Brightness7Icon from '@material-ui/icons/Brightness7'
import React from 'react'
import { useSelector } from 'react-redux'

import { Theme } from './dashboardDuck'
import { ApplicationState } from './store'

type Props = {
  onClick?: () => void
}

export function ThemeButton(props: Props) {
  const theme = useSelector((state: ApplicationState) => state.dashboard.theme)

  function brightnessIcon() {
    if (theme === Theme.Dark) {
      return <Brightness7Icon />
    }
    return <Brightness4Icon />
  }

  return (
    <IconButton
      color="inherit"
      onClick={() => {
        props.onClick && props.onClick()
      }}
    >
      {brightnessIcon()}
    </IconButton>
  )
}
