import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import React, { useState } from 'react'

import { emptyInput, isRequirementMissing, Snippet } from '.'
import { SnippetInputForm } from './SnippetInputForm'

interface Props {
  open: boolean
  snippet: Snippet
  onCancel: () => void
  onConfirm: (snippet: Snippet) => void
}

export function SnippetInputDialog({ snippet, ...props }: Props) {
  const [input, setInput] = useState(emptyInput(snippet))

  const withInputReset = (func: (...args: any) => void) => () => {
    setInput(emptyInput(snippet))
    func()
  }

  const close = withInputReset(props.onCancel)
  const confirm = withInputReset(() => props.onConfirm({ ...snippet, input: input }))

  const updateInput = (field: string, value: string) => {
    setInput(prev => ({ ...prev, [field]: value }))
  }

  return (
    <Dialog open={props.open} onClose={close}>
      <DialogTitle>{snippet.name}</DialogTitle>
      <DialogContent dividers>
        <SnippetInputForm snippet={snippet} input={input ?? {}} onChange={updateInput} />
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary" autoFocus>
          Cancelar
        </Button>
        <Button onClick={confirm} disabled={isRequirementMissing(snippet, input)}>
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
