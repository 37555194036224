import 'react-ace'
import 'ace-builds/src-noconflict/mode-ruby'
import 'ace-builds/src-noconflict/theme-github'

import 'ace-builds/src-noconflict/theme-pastel_on_dark'
import 'ace-builds/src-noconflict/theme-solarized_dark'
import 'ace-builds/src-noconflict/theme-dracula'

import { Box, Button, Grid, Toolbar } from '@material-ui/core'
import React, { useState } from 'react'
import AceEditor from 'react-ace'
import Split from 'react-split'

import { QueryState } from './queryDuck'
import { QueryPanel } from './QueryPanel'
import { useSelector } from 'react-redux'
import { ApplicationState } from './store'
import { Theme } from './dashboardDuck'

interface Props extends QueryState {
  expression: string
  panelSizes: number[]
  onRun?: () => void
  onExpressionChange?: (expression: string) => void
  onSave?: () => void
  onChangePanelSizes: (sizes: number[]) => void
}

export const Editor = (props: Props, context: any) => {
  const stateTheme = useSelector((state: ApplicationState) => state.dashboard.theme)
  const [editorHeight, setEditorHeight] = useState(`calc(${props.panelSizes[0]}% - 5px)`)

  function editorTheme() {
    if (stateTheme === Theme.Dark) {
      return 'dracula'
    }
    return 'github'
  }

  return (
    <Box height="100%">
      <Box height="calc(100% - 120px)" className="split-container">
        <Split
          direction="vertical"
          sizes={props.panelSizes}
          onDragEnd={(sizes: [number, number]) => {
            props.onChangePanelSizes(sizes)
            setEditorHeight(`calc(${sizes[0]}% - 5px)`)
          }}
        >
          <AceEditor
            mode="ruby"
            theme={editorTheme()}
            onChange={(value, event) => {
              props.onExpressionChange && props.onExpressionChange(value)
            }}
            value={props.expression}
            height={editorHeight}
            width="100%"
            commands={[
              {
                name: 'Run',
                bindKey: { win: 'Ctrl-Enter', mac: 'Command-Enter' },
                exec: () => {
                  props.onRun && props.onRun()
                }
              }
            ]}
          />
          <div style={{ overflow: 'auto' }}>
            <QueryPanel {...props} />
          </div>
        </Split>
      </Box>
      <Toolbar>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                props.onSave && props.onSave()
              }}
            >
              Adicionar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                props.onRun && props.onRun()
              }}
            >
              Executar
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </Box>
  )
}
