import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch
} from '@material-ui/core'
import React, { useState } from 'react'
import { clear } from 'redux-localstorage-simple'

import { reducers } from './store'
import { useKeyBinding } from './useKeyBinding'

type Store = Exclude<keyof typeof reducers, 'authentication'>
interface StoreConfig {
  label: string
  selected: boolean
}

const stores: { [key in Store]: StoreConfig } = {
  dashboard: { label: 'Dashboard', selected: false },
  layout: { label: 'Layout', selected: false },
  query: { label: 'Query', selected: false },
  snippet: { label: 'Operações', selected: false },
  userSelector: { label: 'Usuário', selected: false }
}

const legacyStores = ['dashboard', 'snippet', 'userSelector', 'query', 'layout']

export function ClearLocalStorageDialog() {
  const [selectedStores, setSelectedStores] = useState(stores)
  const [legacyStoresSelected, setLegacyStoresSelected] = useState(false)
  const [open, setOpen] = useState(false)

  const clearStores = () => {
    Object.entries(selectedStores).forEach(([store, config]) => {
      if (!config.selected) return
      clear({ namespace: `redux_localstorage_${store}` })
    })
    if (legacyStoresSelected) {
      legacyStores.forEach(store => {
        clear({ namespace: `redux_localstorage_simple_${store}` })
      })
    }
    setSelectedStores(stores)
    setLegacyStoresSelected(false)
    setOpen(false)
  }

  const select = (store: Store) => {
    setSelectedStores(prev => ({
      ...prev,
      [store]: { ...prev[store], selected: !prev[store].selected }
    }))
  }

  useKeyBinding((event: KeyboardEvent) => {
    if (event.ctrlKey && event.altKey && event.key === 'l') {
      setOpen(prev => !prev)
    }
  }, document)

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Limpar configurações</DialogTitle>
      <DialogContent>
        <Box>
          <FormControl component="fieldset">
            <FormGroup>
              {Object.entries(selectedStores).map(([store, config]) => (
                <FormControlLabel
                  key={store}
                  control={
                    <Checkbox checked={config.selected} onChange={() => select(store as Store)} />
                  }
                  label={config.label}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Box>
        <Box marginTop="10px" marginBottom="10px">
          <FormControl>
            <FormControlLabel
              control={
                <Switch
                  checked={legacyStoresSelected}
                  onChange={() => setLegacyStoresSelected(prev => !prev)}
                  color="secondary"
                />
              }
              label="Legadas"
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} autoFocus>
          Cancelar
        </Button>
        <Button onClick={clearStores}>Limpar</Button>
      </DialogActions>
    </Dialog>
  )
}
