import { AppBar, Grid, Tab, Tabs, Toolbar, withStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { ClearLocalStorageDialog } from './ClearLocalStorageDialog'
import DashboardContainer from './DashboardContainer'
import { EditorContainer } from './EditorContainer'
import { RefreshButtonContainer } from './RefreshButtonContainer'
import { ShowSnippetListButton } from './snippets/ShowSnippetListButton'
import { SnippetDashboard } from './snippets/SnippetDashboard'
import { ThemeButtonContainer } from './ThemeButtonContainer'
import { UserSelectorContainer } from './users/UserSelectorContainer'

interface StyledTabsProps {
  value: number
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void
}

function TabPanel(props: any) {
  const { children, activeTab, index } = props

  if (activeTab !== index) return null

  return (
    <div
      style={{
        display: activeTab === index ? 'block' : 'none',
        width: '100%',
        height: '100%',
        margin: 0,
        padding: 0
      }}
    >
      {children}
    </div>
  )
}

const StyledTabs = withStyles({
  indicator: {
    backgroundColor: '#fff'
  }
})((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />)

export const Main = () => {
  const [activeTab, setActiveTab] = useState(0)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue)
  }

  return (
    <>
      <AppBar color="secondary" position="fixed">
        <Grid container justifyContent="space-between">
          <Grid item>
            <StyledTabs value={activeTab} onChange={handleChange}>
              <Tab label="Dashboard" />
              <Tab label="Operações" />
              <Tab label="Editor" />
            </StyledTabs>
          </Grid>
          <Grid item>
            <Toolbar variant="dense">
              <UserSelectorContainer />
              <RefreshButtonContainer />
              <ThemeButtonContainer />
              <ShowSnippetListButton />
            </Toolbar>
          </Grid>
        </Grid>
      </AppBar>
      <TabPanel activeTab={activeTab} index={0}>
        <DashboardContainer />
      </TabPanel>
      <TabPanel activeTab={activeTab} index={1}>
        <SnippetDashboard />
      </TabPanel>
      <TabPanel activeTab={activeTab} index={2}>
        <EditorContainer />
      </TabPanel>
      <ClearLocalStorageDialog />
    </>
  )
}
