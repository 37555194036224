import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core'
import React from 'react'
import MaskedInput from 'react-text-mask'

import { InjectedInput, Snippet, SnippetInputConfig } from '.'

interface SnippetInputFormProps {
  snippet: Snippet
  input: InjectedInput
  onChange: (field: string, value: string) => void
}

const useStyles = makeStyles(theme => ({
  field: {
    marginTop: theme.spacing(2)
  }
}))

export function SnippetInputForm(props: SnippetInputFormProps) {
  const updateInput = (name: string, value: string) => {
    props.onChange(name, value)
  }
  const classes = useStyles()

  return (
    <div>
      {Object.entries(props.snippet.inputConfig ?? {}).map(([name, config]) => (
        <SnippetInputComponent
          name={name}
          key={name}
          config={config}
          input={props.input}
          value={props.input[name] ?? ''}
          className={classes.field}
          onChange={value => updateInput(name, value)}
        />
      ))}
    </div>
  )
}

interface SnippetInputComponentProps {
  name: string
  config: SnippetInputConfig
  input: InjectedInput
  value: string
  className?: string
  onChange: (value: string) => void
}

function SnippetInputComponent(props: SnippetInputComponentProps) {
  switch (props.config.type) {
    case 'date':
      return <SnippetInputDate {...props} />
    case 'enum':
      return <SnippetInputEnum {...props} />
    case 'number':
      return <SnippetInputNumber {...props} />
    case 'boolean':
      return <SnippetInputBoolean {...props} />
    default:
      return <SnippetInputText {...props} />
  }
}

function SnippetInputNumber({ config, value, ...props }: SnippetInputComponentProps) {
  return (
    <Box className={props.className}>
      <TextField
        variant="outlined"
        fullWidth
        type="number"
        label={config.label ?? props.name}
        helperText={config.helper}
        required={config.required}
        value={value}
        onChange={event => props.onChange(event.target.value)}
      />
    </Box>
  )
}

function SnippetInputText({ config, value, ...props }: SnippetInputComponentProps) {
  return (
    <Box className={props.className}>
      <TextField
        fullWidth
        variant="outlined"
        label={config.label ?? props.name}
        helperText={config.helper}
        required={config.required}
        value={value}
        onChange={event => props.onChange(event.target.value)}
      />
    </Box>
  )
}

function SnippetInputBoolean({ config, value, ...props }: SnippetInputComponentProps) {
  return (
    <Box className={props.className}>
      <FormControl fullWidth>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                required={config.required}
                checked={value === 'true'}
                onChange={event => props.onChange(event.target.checked ? 'true' : 'false')}
              />
            }
            label={config.label ?? props.name}
          />
        </FormGroup>
        {config.helper && <FormHelperText>{config.helper}</FormHelperText>}
      </FormControl>
    </Box>
  )
}

function SnippetInputDate({ config, value, ...props }: SnippetInputComponentProps) {
  return (
    <Box className={props.className}>
      <TextField
        fullWidth
        variant="outlined"
        label={config.label ?? props.name}
        helperText={config.helper}
        required={config.required}
        value={value}
        onChange={event => props.onChange(event.target.value)}
        InputProps={{
          inputComponent: TextDateMask
        }}
      />
    </Box>
  )
}

function TextDateMask(props: any) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask={false}
      placeholder="DD/MM/AAAA"
    />
  )
}

function SnippetInputEnum({ config, value, ...props }: SnippetInputComponentProps) {
  if (config.type !== 'enum') return null
  return (
    <FormControl fullWidth required={config.required} style={{ marginTop: '10px' }}>
      <InputLabel>{config.label ?? props.name}</InputLabel>
      <Select
        label={config.label ?? props.name}
        value={value}
        onChange={event => props.onChange(event.target.value as string)}
      >
        {config.choices.map(choice => (
          <MenuItem key={choice.value} value={choice.value}>
            {choice.label}
          </MenuItem>
        ))}
      </Select>
      {config.helper && <FormHelperText>{config.helper}</FormHelperText>}
    </FormControl>
  )
}
