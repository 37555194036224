import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { DashboardDuck, dashboardDuck } from './dashboardDuck'
import { DuckActions } from './Duck'
import { RefreshButton } from './RefreshButton'
import { ApplicationState } from './store'

interface Props {
  actions: DuckActions<DashboardDuck>
}

export const RefreshButtonContainer = connect(
  (store: ApplicationState) => ({}),
  dispatch => ({
    actions: bindActionCreators(dashboardDuck.creators(), dispatch)
  })
)(
  class RefreshButtonContainer extends Component<Props> {
    render() {
      return (
        <RefreshButton
          onClick={() => {
            this.props.actions.loadAll()
          }}
        />
      )
    }
  }
)
