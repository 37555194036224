import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { DashboardDuck, dashboardDuck } from '../dashboardDuck'
import { DuckActions } from '../Duck'
import { ApplicationState } from '../store'
import { UserSelector } from './UserSelector'
import { UserSelectorDuck, userSelectorDuck, UsersState } from './userSelectorDuck'

interface Props {
  userSelectorActions: DuckActions<UserSelectorDuck>
  dashboardActions: DuckActions<DashboardDuck>
  userSelector: UsersState
}

export const UserSelectorContainer = connect(
  (store: ApplicationState) => ({
    userSelector: store.userSelector
  }),
  dispatch => ({
    userSelectorActions: bindActionCreators(
      userSelectorDuck.creators(),
      dispatch
    ),
    dashboardActions: bindActionCreators(dashboardDuck.creators(), dispatch)
  })
)(
  class UserSelectorContainer extends Component<Props> {
    render() {
      return (
        <UserSelector
          options={this.props.userSelector.list}
          selected={this.props.userSelector.user}
          onInputChange={value => {
            this.props.userSelectorActions.search(value)
          }}
          onSelect={user => {
            this.props.userSelectorActions.select(user)
            this.props.dashboardActions.loadAll()
          }}
        />
      )
    }
  }
)
