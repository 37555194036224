import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Tooltip
} from '@material-ui/core'
import { Code, Dashboard, Warning } from '@material-ui/icons'
import OpenWith from '@material-ui/icons/OpenWith'
import React, { useRef } from 'react'

import { InjectedInput, isRequirementMissing, Snippet, SnippetEffect } from '.'
import { SnippetInputForm } from './SnippetInputForm'
import { SnippetToolbar } from './SnippetToolbar'

interface Props {
  snippet: Snippet
  input: InjectedInput
  onChangeInput: (id: string, field: string, value: string) => void
}

const cardStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden'
}

export function SnippetCard({ snippet, input, ...props }: Props) {
  const element = useRef<HTMLElement | undefined>()
  return (
    <Card ref={element} key={snippet.id} style={cardStyle}>
      <CardHeader
        avatar={<SnippetAvatar {...snippet} />}
        action={
          <Tooltip title="Mover">
            <IconButton size="small" style={{ padding: 0, marginTop: '8px', marginRight: '8px' }}>
              <OpenWith className="draggable-handle" />
            </IconButton>
          </Tooltip>
        }
        title={snippet.name}
        subheader={
          <Box display="flex">
            {`${snippet.category} · ${snippet.version}`}
            <RiskIndicator {...snippet} />
          </Box>
        }
        style={{ alignItems: 'flex-start', paddingBottom: 0 }}
      />
      <CardContent style={{ flex: 1, paddingTop: 5, paddingBottom: 5 }}>
        {snippet.inputConfig && (
          <SnippetInputForm
            snippet={snippet}
            input={input}
            onChange={(field, value) => props.onChangeInput(snippet.id, field, value)}
          />
        )}
      </CardContent>
      <CardActions disableSpacing style={{ paddingTop: 0 }}>
        <SnippetToolbar
          snippet={{ ...snippet, input }}
          requirementMissing={isRequirementMissing(snippet, input)}
          iconStyles={{ copy: { marginLeft: 'auto' } }}
          parentRef={element}
        />
      </CardActions>
    </Card>
  )
}

function RiskIndicator(props: Snippet) {
  if (!props.risky) return null
  return (
    <>
      {` · `}
      <Tooltip title="Operação de risco">
        <Warning style={{ color: '#e1ded2', marginLeft: '5px', fontSize: '1rem' }} />
      </Tooltip>
    </>
  )
}

function SnippetAvatar(props: Snippet) {
  const Icon = props.effect === SnippetEffect.SideEffect ? Code : Dashboard
  return (
    <Avatar>
      <Icon />
    </Avatar>
  )
}
