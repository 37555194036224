import { useSnackbar } from 'notistack'
import { useDispatch } from 'react-redux'

import { injectedInputContent, Snippet, SnippetAction } from '.'
import { dashboardDuck } from '../dashboardDuck'
import { queryDuck } from '../queryDuck'

const snippetMessage = ({ name }: Snippet, action: keyof typeof SNIPPET_MESSAGES) =>
  `Operação '${name}' ${SNIPPET_MESSAGES[action]}!`

const SNIPPET_MESSAGES = {
  add: 'adicionada ao dashboard',
  copy: 'copiada para a área de transferência'
}

const RISK_MESSAGE =
  'Atenção: a operação selecionada foi classificada como um risco. Executá-la pode ter consequências indesejadas.'

export function useSnippetActions(callback: SnippetAction) {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const run = (snippet: Snippet) => {
    dispatch(queryDuck.query(injectedInputContent(snippet)))
    callback(snippet)
  }

  const add = (snippet: Snippet) => {
    dispatch(dashboardDuck.add(injectedInputContent(snippet), snippet.name))
    enqueueSnackbar(snippetMessage(snippet, 'add'), { variant: 'success' })
    callback(snippet)
  }

  const copy = (snippet: Snippet) => {
    navigator.clipboard.writeText(injectedInputContent(snippet))
    enqueueSnackbar(snippetMessage(snippet, 'copy'), { variant: 'success' })
    if (snippet.risky) {
      enqueueSnackbar(RISK_MESSAGE, { variant: 'warning' })
    }
    callback(snippet)
  }

  return { run, add, copy }
}
